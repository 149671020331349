import { useContext, useState } from 'react';
import { ProfileConfiguratorContext } from '../ProfileConfigurator';
import {
  Box,
  CircularProgress,
  Divider,
  Radio,
  Typography,
} from '@mui/material';
import NavigationButtons from '../NavigationButtons';
import { useConfigureManagedProfileMutation } from 'store/api/admin.api';
import { transformProfileConfigurationPayload } from 'utils/transform-profile-configuration-payload';
import { DateInput } from 'components/DateInput';
import dayjs, { Dayjs } from 'dayjs';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import { BasicModal } from 'components/BasicModal';
import { CompletionMessage } from 'components/CompletionMessage';
import { getNextWorkday } from 'utils/get-next-workday';

const ActivationDate = ({ isAdmin }: { isAdmin?: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { handlePrevious, profileConfiguration, reset } = useContext(
    ProfileConfiguratorContext,
  );

  const [bundleType, setBundleType] = useState<
    'default' | 'partner_deployment'
  >('default');
  const [activationDate, setActivationDate] = useState<Dayjs | null>(
    getNextWorkday(),
  );

  const handleEditActivationDate = (date: Dayjs | null) => {
    setActivationDate(date);
  };

  const [configure, { isLoading, isSuccess }] =
    useConfigureManagedProfileMutation();

  const handleFinishConfiguration = () => {
    configure(
      transformProfileConfigurationPayload({
        ...profileConfiguration,
        activationDate: activationDate?.toISOString() || dayjs().toISOString(),
        partnerDeployment: bundleType === 'partner_deployment',
      }),
    );
  };

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column" gap={2}>
        <Box
          flexGrow={1}
          overflow="auto"
          pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
          pt={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        >
          <Typography color="primary" fontWeight={600} mb={1}>
            Summary
          </Typography>
          <Box
            p={2}
            display="grid"
            gridTemplateColumns="auto auto"
            width="min-content"
            columnGap={4}
            rowGap={1}
          >
            <Typography color="blue.main" variant="body1" whiteSpace="nowrap">
              First Name:
            </Typography>
            <Typography variant="body1" color="primary">
              {profileConfiguration.profileSettings.firstName || '-'}
            </Typography>
            <Typography color="blue.main" variant="body1" whiteSpace="nowrap">
              Last Name:
            </Typography>
            <Typography variant="body1" color="primary">
              {profileConfiguration.profileSettings.lastName || '-'}
            </Typography>
            <Typography color="blue.main" variant="body1" whiteSpace="nowrap">
              Custom Identifier:
            </Typography>
            <Typography variant="body1" color="primary">
              {profileConfiguration.profileSettings.customIdentifier || '-'}
            </Typography>
            <Typography color="blue.main" variant="body1" whiteSpace="nowrap">
              BoundaryCare Identifier:
            </Typography>
            <Typography variant="body1" color="primary">
              {profileConfiguration.bcIdentifier || '-'}
            </Typography>
          </Box>
          {isAdmin && (
            <>
              <Divider sx={{ mt: 2, mb: { xxs: 3, sm: 4 } }} />
              <Typography color="primary" fontWeight={600} mb={1}>
                Equipment
              </Typography>
              <Box p={1} display="flex" flexDirection="column" gap={1}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Box display="flex" alignItems="center">
                    <Radio
                      id="default-bundle"
                      value="default"
                      checked={bundleType === 'default'}
                      onChange={() => setBundleType('default')}
                    />
                    <Typography component="label" htmlFor="default-bundle">
                      Default
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Radio
                      id="partner-deployment"
                      value="custom"
                      checked={bundleType === 'partner_deployment'}
                      onChange={() => setBundleType('partner_deployment')}
                    />
                    <Typography component="label" htmlFor="partner-deployment">
                      Partner Deployment
                    </Typography>
                  </Box>
                </Box>
                <HelperText sx={{ maxWidth: ({ spacing }) => spacing(60) }}>
                  *By default, both the <strong>BoundaryCare</strong> Watch and
                  Phone will be delivered directly to the end-user and their
                  care team. Final configuration steps like joining the local
                  wifi network will happen on the phone.
                </HelperText>
              </Box>
            </>
          )}
          <Divider sx={{ mt: 2, mb: { xxs: 3, sm: 4 } }} />
          <Typography color="primary" fontWeight={600} mb={1}>
            Start date
          </Typography>
          <Box p={1} display="flex" flexDirection="column" gap={1}>
            <DateInput
              onChange={handleEditActivationDate}
              disablePast
              disableWeekends
              defaultValue={getNextWorkday()}
              minDate={getNextWorkday()}
              maxDate={dayjs().add(1, 'month')}
            />
            <HelperText sx={{ pl: 1, maxWidth: ({ spacing }) => spacing(60) }}>
              *Please note that the <strong>BoundaryCare</strong> service will
              start on the date you have selected. Make sure to review and
              confirm the activation date before finalizing profile creation.
            </HelperText>
          </Box>
        </Box>
        <NavigationButtons
          hideNextIcon
          nextButtonText="Configure Profile"
          onPreviousClick={handlePrevious}
          onNextClick={handleFinishConfiguration}
          nextButtonDisabled={isLoading || isSuccess}
          previousButtonDisabled={isLoading || isSuccess}
        />
      </Box>
      {isSuccess || isLoading ? (
        <BasicModal open={isModalOpen} hideCloseButton>
          {isSuccess ? (
            <CompletionMessage
              onButtonClick={() => {
                setIsModalOpen(false);
                reset();
              }}
              status="success"
              title="Profile configured successfully!"
              subtitle="Follow any remaining instructions on the individual’s watch and/or phone. A notification email has been sent to all of the individual’s caregivers."
              sx={{ p: 0 }}
            />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h5"
                color="primary"
                pb={1}
                px={1}
                textAlign="center"
              >
                Configuring profile...
              </Typography>
              <Typography
                variant="body1"
                color="blue.main"
                textAlign="center"
                pb={4}
                px={1}
              >
                We are currently setting up your profile. Please stay on this
                page, as it may take a few moments.
              </Typography>
              <CircularProgress size={72} />
            </Box>
          )}
        </BasicModal>
      ) : null}
    </>
  );
};

export default ActivationDate;
